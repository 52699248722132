import moment from "moment";
import slugify from "slugify";
import styles from "./blog.module.css";
import {
  Typography,
  Container,
  Card,
  CardMedia,
  CardActions,
  CardContent,
  CardActionArea,
  ButtonBase,
} from "@mui/material";
import Title from "../title/Title";
import { useHistory } from "react-router-dom";
const Blogs = ({ News, NewsRef }) => {
  const history = useHistory();
  return (
    <Container>
      {News && News.length > 0 && (
        <>
          {" "}
          <div ref={NewsRef} className={styles.blog}>
            <Title title={"Blogs"} span={"Blogs"} />

            <div className={styles.news__list__wrapper}>
              {News.map((item, index) => {
                return (
                  <Card raised key={index}>
                    <ButtonBase
                      onClick={() => {
                        history.push({
                          pathname: `/news/${slugify(item.title)}`,
                          state: { item },
                        });
                      }}
                      style={{ cursor: "pointer" }}
                      disableRipple
                      className={styles.card__actionarea}
                    >
                      <div
                        style={{
                          overflow: "hidden",
                          alignSelf: "flex-start",
                        }}
                      >
                        <CardMedia
                          component="img"
                          height="194"
                          image={item.headingImage}
                          alt="Paella dish"
                          className={styles.image}
                        />
                      </div>
                      <CardContent style={{ padding: "10px" }}>
                        <Typography
                          variant="body1"
                          style={{ color: "rgb(20, 61, 89)" }}
                        >
                          {item.title.charAt(0).toUpperCase() +
                            item.title.slice(1)}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Typography variant="body2" color="text.secondary">
                          Written by{" "}
                          <span style={{ fontWeight: "600" }}>
                            {item.author}
                          </span>{" "}
                          |{" "}
                          <span style={{ fontWeight: "600" }}>
                            {moment(item.createdAt).fromNow()}
                          </span>
                        </Typography>
                      </CardActions>
                    </ButtonBase>
                  </Card>
                );
              })}
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default Blogs;
