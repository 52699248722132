import { Typography } from "@mui/material";
import React from "react";
import Layout from "../Layout";

const AddNews = ({ companies }) => {
  return (
    <Layout>
      <div className="register__company">
        <Typography variant="h6" className="page-header">
          add on web
        </Typography>
      </div>
    </Layout>
  );
};

export default AddNews;
