import { Container } from "@mui/material";
import Title from "../title/Title";
import styles from "./about.module.css";
const About = ({ AboutRef, about }) => {
  return (
    <Container>
      <div className={styles.about} ref={AboutRef}>
        <Title title={"About"} span={"About"} />
        <div className={styles.about__content}>{about}</div>
      </div>
    </Container>
  );
};

export default About;
