import React from "react";
import styles from "./title.module.css";
function Title({ title, span }) {
  return (
    <div className={styles.Title}>
      <h3>
        {title}
        <span> {span}</span>
      </h3>
    </div>
  );
}

export default Title;
