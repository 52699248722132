import { Typography } from "@mui/material";
import RegisterCompanyComponent from "../RegisterCompanyComponent";
import Layout from "../Layout";
import Reg from "../Home/Reg";
const RegisterCompany = () => {
  return (
    <Layout>
      <div className="register__company">
        <Typography variant="h6" className="page-header">
          <Reg />
        </Typography>
      </div>
    </Layout>
  );
};

export default RegisterCompany;
