import { Container } from "@mui/material";
import Title from "../title/Title";
import styles from "./service.module.css";
import SettingsIcon from "@mui/icons-material/Settings";

const Service = ({ ServiceRef, Services }) => {
  return (
    <Container>
      <div className={styles.service} ref={ServiceRef}>
        <Title title={"Services"} span={"Services"} />
        <div className={styles.service_list}>
          {Services.map((service, index) => {
            return (
              <div key={index} className={styles.service__values}>
                <div className={styles.service__icons}>
                  <SettingsIcon />
                </div>
                <h4>{service.name}</h4>
              </div>
            );
          })}
        </div>
      </div>
    </Container>
  );
};

export default Service;
