import React, { useEffect, useState } from "react";
import { url } from "../URL/url";
import axios from "axios";
import {
  Card,
  CardMedia,
  CardActions,
  CardContent,
  ButtonBase,
} from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import Background from "./hero.jpg";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import queryString from "query-string";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import _ from "lodash";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Typography,
  Pagination,
  TextField,
  InputAdornment,
  PaginationItem,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import SliderComponent from "../Utiles/Slider";
import Slider from "react-slick";
import RegisterCompanyComponent from "../RegisterCompanyComponent";
import Controls from "../controls/Controls";
import Popup from "../customHelpers/Popup";
import CompanyForm from "../Utiles/CompanyForm";
import NewsForm from "../NewsForm";
import Notification from "../customHelpers/Notification";
import UpdateCompanyForm from "../Utiles/UpdateCompanyForm";

const StyledInputBaseSearch = styled(TextField)(({ theme }) => ({
  color: "rgb(20, 61, 89)",
  borderColor: "rgb(20, 61, 89)",
  // borderRadius: "15px",
  outline: "none",
  "& .MuiInputBase-input": {
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  "& label.Mui-focused": {
    color: "rgb(20, 61, 89)",
  },
  "& .MuiOutlinedInput-root": {
    " &.Mui-focused fieldset": {
      borderColor: "rgb(20, 61, 89)",
    },
  },
}));

function slugify(str) {
  str = str.replace(/^\s+|\s+$/g, "");

  // Make the string lowercase
  str = str.toLowerCase();

  // Remove accents, swap ñ for n, etc
  var from =
    "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
  var to =
    "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  // Remove invalid chars
  str = str
    .replace(/[^a-z0-9 -]/g, "")
    // Collapse whitespace and replace by -
    .replace(/\s+/g, "-")
    // Collapse dashes
    .replace(/-+/g, "-");

  return str;
}
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} color="white" />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "rgb(20,61,89)",
  borderRadius: "5px",
  color: "white",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const newsPerPage = 12;
function Reg(props) {
  const [openPopup, setOpenPopup] = React.useState(false);
  const [fetching, setFetching] = React.useState(false);
  const [openNewsPopup, setNewsOpenPopup] = React.useState(false);
  const [Q, setQ] = useState("");
  const history = useHistory();
  const newsRef = React.createRef();
  const [companies, setCompanies] = useState([]);

  const [loading, setLoading] = React.useState(false);
  const [notify, setNotify] = useState(false);

  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    scrollTo(newsRef);
    setPage(value);
  };
  const [ads, setAds] = useState({
    spaceOneAds: [],
    spaceTwoAds: [],
    spaceThreeAds: [],
  });
  const scrollTo = (ref) => {
    if (ref && ref.current /* + other conditions */) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChangeEx = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const { spaceOneAds, spaceTwoAds, spaceThreeAds } = ads;
  React.useEffect(() => {
    getAllAds();
  }, []);
  const getAllAds = () => {
    axios.get(`${url}api/get-all-ads`).then((response) => {
      if (response.data.err) {
        console.log(response.data.err);
      } else {
        const adOne = response.data.result.filter((ad) => ad.adSpace == "one");
        const adTwo = response.data.result.filter((ad) => ad.adSpace == "two");
        const adThree = response.data.result.filter(
          (ad) => ad.adSpace == "three"
        );
        setAds({
          spaceOneAds: adOne,
          spaceTwoAds: adTwo,
          spaceThreeAds: adThree,
        });
      }
    });
  };

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const matches = useMediaQuery("(min-width:852px)");
  const matchescollaps = useMediaQuery("(min-width:918px)");
  // const handleSearch = (rows) => {
  //   let tempArray = [];
  //   rows.filter((row) =>
  //     row[0].toLowerCase().trim().startsWith(Q.toLowerCase().trim())
  //       ? tempArray.push(row[1])
  //       : false
  //   );
  //   return tempArray;
  // };

  const [News, setNews] = useState([]);
  const numNews = News.length;
  useEffect(() => {
    setLoading(true);
    axios.get(`${url}api/get-news`).then((response) => {
      console.log(response.data);
      setLoading(false);
      setNews(
        response.data.result.sort((a, b) =>
          a.createdAt < b.createdAt ? 1 : b.createdAt < a.createdAt ? -1 : 0
        )
      );
    });
  }, []);
  React.useEffect(() => {
    let Timer;
    clearTimeout(Timer);
    setFetching(true);
    Timer = setTimeout(async () => {
      handleSearch();
    }, 1000);
    return () => {
      clearTimeout(Timer);
    };
  }, [Q]);
  const handleSearch = () => {
    axios
      .get(
        `${url}api/search-companies-by-name/name?${queryString.stringify({
          search: Q,
        })}`
      )
      .then((response) => {
        let index = response.data.result
          .map((o) => [o["name"], o])
          .sort(([a], [b]) => (a > b) - (a < b));
        setCompanies(index);
        setFetching(false);
      });
  };
  window.addEventListener("scroll", () => {
    const element = document.getElementsByClassName("search__bar")[0];
    if (element) {
      if (window.scrollY > 200) {
        element.classList.remove("show");
      } else {
        element.classList.add("show");
      }
    }
  });

  const loadedNews = News.slice(
    page * newsPerPage - newsPerPage,
    page * newsPerPage
  );

  return (
    <div>
      {loading ? (
        <div className="loading">
          <CircularProgress />
        </div>
      ) : (
        <div className="content">
          {matches && (
            <>
              <div className="forms">
                <Accordion
                  expanded={matchescollaps ? true : expanded === "panel1"}
                  onChange={handleChangeEx("panel1")}
                  className="form"
                >
                  <AccordionSummary
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <div className="accordionSummery">
                      <Typography>Register your company</Typography>
                      OR
                      <Controls.Button
                        text="Update your company"
                        variant="contained"
                        className="Button"
                        onClick={() => {
                          setOpenPopup(true);
                        }}
                        color="rgba(244,151,3,.8)"
                      />
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <RegisterCompanyComponent companies={companies} />
                  </AccordionDetails>
                </Accordion>
                {/* <Accordion
                expanded={matchescollaps ? true : expanded === "panel2"}
                onChange={handleChangeEx("panel2")}
                className="form"
              >
                <AccordionSummary
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>Contact Us</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ContactUsComponent />
                </AccordionDetails>
              </Accordion> */}
                <Controls.Button
                  variant="contained"
                  className="Button"
                  onClick={() => {
                    setNewsOpenPopup(true);
                  }}
                  text="Add news about your company"
                />
              </div>
            </>
          )}
        </div>
      )}
      <Popup
        title="Update your company."
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <UpdateCompanyForm home={true} />
      </Popup>
      <Popup
        title="News Form."
        openPopup={openNewsPopup}
        setOpenPopup={setNewsOpenPopup}
      >
        <NewsForm companies={companies} />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
export default withRouter(Reg);
