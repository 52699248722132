import { Container } from "@mui/material";
import Title from "../title/Title";
import styles from "./contactUs.module.css";
import MapContainer from "../../Company/MapContainer ";
import CampaignIcon from "@mui/icons-material/Campaign";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import FaxIcon from "@mui/icons-material/Fax";
import MapIcon from "@mui/icons-material/Map";
import LanguageIcon from "@mui/icons-material/Language";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { SocialIcon } from "react-social-icons";
const ContactUs = ({ ContactUsRef, CompanyInfo }) => {
  return (
    <Container>
      <div className={styles.contactus} ref={ContactUsRef}>
        <Title title={"Contact Us"} span={"Contact Us"} />

        <div className={styles.contact__methods}>
          <div>
            <div className={styles.contact__container}>
              <div className={styles.contact__icons}>
                <MapIcon />
              </div>
              <div className={styles.contact__values}>
                {CompanyInfo.Address?.state && (
                  <div className={styles.content}>
                    <h4>State:</h4>
                    <p>{CompanyInfo.Address?.state}</p>
                  </div>
                )}
                {CompanyInfo.Address?.city && (
                  <div className={styles.content}>
                    <h4>City:</h4>
                    <p>{CompanyInfo.Address?.city}</p>
                  </div>
                )}
                {CompanyInfo.Address?.wereda && (
                  <div className={styles.content}>
                    <h4>Wereda:</h4>
                    <p>{CompanyInfo.Address?.wereda}</p>
                  </div>
                )}
                {CompanyInfo.Address?.sub_city && (
                  <div className={styles.content}>
                    <h4> Sub city:</h4>
                    <p>{CompanyInfo.Address?.sub_city} </p>
                  </div>
                )}
                {CompanyInfo.Address?.street_no && (
                  <div className={styles.content}>
                    <h4>Street:</h4>
                    <p>{CompanyInfo.Address?.street_no}</p>
                  </div>
                )}
                {CompanyInfo.Address?.kebele && (
                  <div className={styles.content}>
                    <h4>Kebele:</h4>
                    <p>{CompanyInfo.Address?.kebele} </p>
                  </div>
                )}
                {CompanyInfo.Address?.pobox && (
                  <div className={styles.content}>
                    <h4>P.O.Box:</h4>
                    <p>{CompanyInfo.Address?.pobox}</p>
                  </div>
                )}
              </div>
            </div>
            {CompanyInfo.web && (
              <div className={styles.contact__container}>
                <div className={styles.contact__icons}>
                  <LanguageIcon />
                </div>
                <div className={styles.contact__values}>
                  <div className={styles.content}>
                    <h4>Web:</h4>
                    <a target="_blank" href={CompanyInfo.web}>
                      {CompanyInfo.web}
                    </a>
                  </div>
                </div>
              </div>
            )}
            {CompanyInfo.email && (
              <div className={styles.contact__container}>
                <div className={styles.contact__icons}>
                  <MailOutlineIcon />
                </div>
                <div className={styles.contact__values}>
                  <div className={styles.content}>
                    <h4>Email:</h4>
                    <a href={`mailto:${CompanyInfo.email}`}>
                      {CompanyInfo.email}
                    </a>
                  </div>
                </div>
              </div>
            )}
            {CompanyInfo.Faxes?.length > 0 && (
              <div className={styles.contact__container}>
                <div className={styles.contact__icons}>
                  <FaxIcon />
                </div>
                <div className={styles.contact__values__array}>
                  <h4>Fax Numbers:</h4>
                  {CompanyInfo.Faxes.map((number, index) => (
                    <p key={index}>{number.fax}</p>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div>
            {CompanyInfo.PhoneNumbers?.length > 0 && (
              <div className={styles.contact__container}>
                <div className={styles.contact__icons}>
                  <PhoneAndroidIcon />
                </div>
                <div className={styles.contact__values__array}>
                  <h4>Phone Numbers:</h4>

                  {CompanyInfo.PhoneNumbers.map((number, index) => (
                    <p key={index}>{number.phone_no}</p>
                  ))}
                </div>
              </div>
            )}
            {CompanyInfo.OfficeNumbers?.length > 0 && (
              <div className={styles.contact__container}>
                <div className={styles.contact__icons}>
                  <LocalPhoneIcon />
                </div>
                <div className={styles.contact__values__array}>
                  <h4>Office Numbers:</h4>

                  {CompanyInfo.OfficeNumbers.map((number, index) => (
                    <p key={index}>{number.office_no}</p>
                  ))}
                </div>
              </div>
            )}
            {CompanyInfo.SocialMedia?.length > 0 && (
              <div className={styles.social__links__container}>
                <h4>Social Medias:</h4>

                <div className={styles.social__links}>
                  {CompanyInfo.SocialMedia.map((account, index) => (
                    <div className={styles.values} key={index}>
                      <SocialIcon
                        label={account.social_media}
                        target="_blank"
                        url={account.social_media}
                        network={
                          account.social_media.includes("t.me")
                            ? "telegram"
                            : ""
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        {CompanyInfo.Address &&
          CompanyInfo.Address.location &&
          CompanyInfo.Address.location.coordinates[1] &&
          CompanyInfo.Address.location.coordinates[0] && (
            <MapContainer
              coordinates={CompanyInfo.Address.location.coordinates}
              companyName={CompanyInfo.name}
            />
          )}
      </div>
    </Container>
  );
};

export default ContactUs;
