import React, { Fragment } from "react";
import styles from "./topnav.module.css";
import { Image } from "react-bootstrap";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Box } from "@mui/system";
import { useHistory } from "react-router-dom";
// import logo from "../../Files/company-portal/logo-on-christmas.png";
import logo from "../../Files/company-portal/infoEthiopiaLogo2.png";
const Topnav = ({
  AboutRef,
  ContactUsRef,
  IndexRef,
  ServiceRef,
  companyLogo,
  name,
  scrollTo,
  NewsRef,
  Newslength,
}) => {
  const history = useHistory();
  const logoRef = React.useRef();
  const [state, setState] = React.useState({
    left: false,
  });
  window.addEventListener("scroll", () => {
    if (logoRef.current) {
      if (window.scrollY > 100) {
        logoRef.current.style.height = "65px";
      } else {
        logoRef.current.style.height = "60px";
      }
    }
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const Lists = () => {
    return (
      <div className={styles.nav__menu}>
        {/* <li onClick={() => scrollTo(IndexRef)}>Home</li> */}
        <li onClick={() => scrollTo(ServiceRef)}>Service</li>
        <li onClick={() => scrollTo(AboutRef)}>About</li>
        <li onClick={() => scrollTo(ContactUsRef)}>Contact </li>
        {Newslength && <li onClick={() => scrollTo(NewsRef)}>Blogs </li>}
        {/* <li>
          <IconButton onClick={() => history.goBack()}>
            <ArrowBackIosNewIcon />
          </IconButton>
        </li> */}
      </div>
    );
  };
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className={styles.sidebar__logo} onClick={() => scrollTo(IndexRef)}>
        {companyLogo && (
          <Image
            src={companyLogo}
            fluid
            className="ImageLogo"
            style={{ width: "100px", height: "100px" }}
          />
        )}
      </div>
      <Lists />
    </Box>
  );
  const BackIcon = () => (
    <IconButton
      style={{ padding: "10px 2px" }}
      onClick={() => history.goBack()}
    >
      <div className={styles.backIcon}>
        <ArrowBackIosNewIcon />
        <div className={styles.backIcon__logo}>
          <Image layout="responsive" src={logo} alt="Picture of the author" />
        </div>
      </div>
    </IconButton>
  );
  return (
    <Fragment>
      <div className={styles.topnav}>
        <div className={styles.topnav__left} onClick={() => scrollTo(IndexRef)}>
          <div className={styles.topnav__logo} ref={logoRef}>
            {companyLogo && (
              <Image
                layout="responsive"
                src={companyLogo}
                alt="Picture of the author"
              />
            )}
          </div>
          <div style={{ textTransform: "uppercase" }}>
            <span>{name}</span>
          </div>
        </div>
        <div>
          <Lists />
        </div>
        <BackIcon />
      </div>
      <div className={styles.menubar}>
        {["left"].map((anchor) => (
          <React.Fragment key={anchor}>
            <div className={styles.menubar__content}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <IconButton onClick={toggleDrawer(anchor, true)}>
                  <MenuIcon />
                </IconButton>
                <div style={{ textTransform: "uppercase" }}>
                  <span>{name}</span>
                </div>
              </div>
              <BackIcon />
            </div>

            <SwipeableDrawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
              onOpen={toggleDrawer(anchor, true)}
            >
              {list(anchor)}
            </SwipeableDrawer>
          </React.Fragment>
        ))}
      </div>
    </Fragment>
  );
};

export default Topnav;
